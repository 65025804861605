/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack} from '@servicetitan/design-system';
import {ResourceLink} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "meta-links",
    style: {
      position: "relative"
    }
  }, "Meta Links", React.createElement(_components.a, {
    href: "#meta-links",
    "aria-label": "meta links permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Anvil Roadmap",
    subtitle: "Updated quarterly",
    url: "https://servicetitan.atlassian.net/jira/software/c/projects/DS/boards/551/roadmap",
    iconName: "map"
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "GitHub Repository",
    subtitle: "Source code of project",
    url: "https://github.com/servicetitan/anvil",
    externalUrl: true,
    iconName: "device_hub"
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Changelog",
    subtitle: "Release history of Anvil",
    url: "https://github.com/servicetitan/anvil/releases",
    externalUrl: true,
    iconName: "change_history"
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Contributing to Anvil",
    subtitle: "For designers and developers",
    url: "/resources/contributing/"
  }))), "\n", React.createElement(_components.h2, {
    id: "figma-assets",
    style: {
      position: "relative"
    }
  }, "Figma Assets", React.createElement(_components.a, {
    href: "#figma-assets",
    "aria-label": "figma assets permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Anvil Components",
    subtitle: "V2 with Variants",
    url: "https://www.figma.com/file/KLWxYdHAdFNvmt4WjEbCid/Anvil",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Illustrations Library",
    subtitle: "Used within the product",
    url: "https://www.figma.com/file/eNf6VgmY250UaEAxE9kaUl/Illustrations-Library?node-id=0%3A1",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Icons",
    subtitle: "From Material Icons",
    url: "https://www.figma.com/file/YN0ukVqAnKgVCqvih53757/Icons",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Stickersheet",
    subtitle: "Community examples of UIs",
    url: "https://www.figma.com/file/4WqWfRTP5HPf9Cwn9btQIB/Stickersheet?node-id=1%3A7&viewport=1044%2C694%2C1",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Color Accessibility",
    subtitle: "Guide to color a11y",
    url: "https://www.figma.com/proto/xDpwL1pomsuEb4vd8X42PT/Anvil-Color-Accessibility-Guide?page-id=22%3A49&type=design&node-id=464-7989&viewport=4366%2C2246%2C0.82&t=lHVkzQcifUT0au4i-1&scaling=min-zoom&starting-point-node-id=464%3A8185&mode=design",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  })), "\n", React.createElement(_components.h2, {
    id: "font-links",
    style: {
      position: "relative"
    }
  }, "Font Links", React.createElement(_components.a, {
    href: "#font-links",
    "aria-label": "font links permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Nunito Sans (Google Fonts)",
    subtitle: "2 Weights, 2 Styles",
    url: "https://fonts.google.com/specimen/Nunito+Sans?selection.family=Nunito+Sans:400,600",
    iconName: "font_download",
    download: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  })), "\n", React.createElement(_components.h2, {
    id: "code-links",
    style: {
      position: "relative"
    }
  }, "Code Links", React.createElement(_components.a, {
    href: "#code-links",
    "aria-label": "code links permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Anvil Storybook",
    url: "https://anvil.st.dev/",
    externalUrl: true,
    iconName: "code"
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "CodeSandbox Sample",
    url: "https://codesandbox.io/s/b2jl1",
    externalUrl: true,
    iconName: "code"
  }))), "\n", React.createElement(_components.h2, {
    id: "platform-links",
    style: {
      position: "relative"
    }
  }, "Platform Links", React.createElement(_components.a, {
    href: "#platform-links",
    "aria-label": "platform links permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3,
    className: "w-100",
    wrap: "wrap"
  }, React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "UI Kit",
    subtitle: "Frontend Platform Repository",
    url: "https://github.com/servicetitan/uikit",
    externalUrl: true
  })), React.createElement(Stack.Item, {
    fill: true,
    style: {
      flexBasis: '300px'
    }
  }, React.createElement(ResourceLink, {
    title: "Platform Documentation",
    subtitle: "Docs and blog of the platform team",
    url: "https://docs.st.dev/docs/frontend/intro",
    externalUrl: true
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
